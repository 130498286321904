var render = function () {
  var _vm$errorMessage, _vm$errorMessage2, _vm$errorMessage3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "new-schema pb-6 w-full min-h-contain"
  }, [_c('div', {
    staticClass: "font-bold text-3xl mb-6 flex items-center"
  }, [_c('button', {
    staticClass: "mr-3 w-8 h-8 flex justify-center items-center focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.toLMSClassListMenu();
      }
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left"
    }
  })], 1), _c('p', [_vm._v(_vm._s(_vm.$route.meta.label) + " "), _c('span', {
    staticClass: "font-normal inline-block"
  }, [_vm._v("(New Schema)")])])]), _vm.hasIssue ? _c('div', {
    staticClass: "text-xs bg-yellow-10 border border-yellow-100 rounded-xl shadow-soft px-4 py-4 mt-4 mb-4"
  }, [_c('p', {
    staticClass: "flex text-xs gap-4 items-center"
  }, [_c('Warning', {
    attrs: {
      "color": "brown"
    }
  }), _vm._m(0)], 1)]) : _vm._e(), _c('div', {
    staticClass: "tab flex justify-between items-end pl-4"
  }, [_c('div', {
    staticClass: "flex gap-2"
  }, [_c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Detail' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Detail');
      }
    }
  }, [_vm._v(" Detail ")]), _c('div', {
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg",
    class: [_vm.selectedTab === 'Activities' ? 'font-bold bg-white' : 'bg-neutral-50', _vm.isActivitiesTabEnable ? 'cursor-pointer' : 'cursor-not-allowed'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Activities');
      }
    }
  }, [_vm._v(" Activities ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditMode,
      expression: "isEditMode"
    }],
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Students' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Students');
      }
    }
  }, [_vm._v(" Students ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditMode,
      expression: "isEditMode"
    }],
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Grade' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Grade');
      }
    }
  }, [_vm._v(" Grade ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isEditMode,
      expression: "isEditMode"
    }],
    staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
    class: [_vm.selectedTab === 'Instructor Feedback' ? 'font-bold bg-white' : 'bg-neutral-50'],
    on: {
      "click": function click($event) {
        return _vm.selectTab('Instructor Feedback');
      }
    }
  }, [_vm._v(" Instructor Feedback ")])]), _c('div', {
    staticClass: "flex justify-end py-2"
  }, [_c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab !== 'Instructor Feedback' && _vm.selectedTab !== 'Grade' && _vm.selectedTab !== 'Students',
      expression: "selectedTab !== 'Instructor Feedback' && selectedTab !== 'Grade' && selectedTab !== 'Students'"
    }],
    attrs: {
      "disabled": _vm.disableSubmit,
      "buttonText": _vm.isEditMode ? 'Update' : _vm.isCopyMode ? 'Copy' : 'Submit',
      "size": "compact"
    },
    on: {
      "action": function action($event) {
        return _vm.save();
      }
    }
  })], 1)]), _vm.selectedTab === 'Detail' ? [_c('div', {
    staticClass: "shadow-small rounded-lg bg-white flex"
  }, [_c('div', {
    staticClass: "form w-1/2 p-6 shadow-border-r"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.filterWorkflow,
      "label": "Workflow Type (Optional)",
      "default": _vm.selectedWorkflow,
      "placeholder": "Select Workflow",
      "optionLabel": "name"
    },
    model: {
      value: _vm.selectedWorkflow,
      callback: function callback($$v) {
        _vm.selectedWorkflow = $$v;
      },
      expression: "selectedWorkflow"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "isLabelRequire": "",
      "label": "Schedule Name",
      "borderEnabled": true,
      "placeholder": "Schedule Name",
      "error": _vm.error['name'],
      "required": ""
    },
    model: {
      value: _vm.scheduleName,
      callback: function callback($$v) {
        _vm.scheduleName = $$v;
      },
      expression: "scheduleName"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_vm._m(1), _c('Dropdown', {
    attrs: {
      "options": _vm.typeList,
      "disabled": _vm.isEditMode,
      "optionLabel": "name",
      "default": _vm.selectedClassType,
      "error": _vm.error['deliveryType'],
      "placeholder": "Select Class Type"
    },
    model: {
      value: _vm.selectedClassType,
      callback: function callback($$v) {
        _vm.selectedClassType = $$v;
      },
      expression: "selectedClassType"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(" Class "), !_vm.visibleClass ? _c('span', {
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("(Please select the class type first)")]) : _vm._e(), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]), _c('Dropdown', {
    attrs: {
      "options": _vm.list_kelas,
      "default": _vm.selectedClass,
      "placeholder": "Select Class",
      "optionLabel": "nama",
      "error": _vm.error['coreClassId'],
      "disabled": !_vm.visibleClass || _vm.isEditMode
    },
    model: {
      value: _vm.selectedClass,
      callback: function callback($$v) {
        _vm.selectedClass = $$v;
      },
      expression: "selectedClass"
    }
  })], 1), _vm.coreClassVariantList.length > 0 ? _c('div', {
    staticClass: "mb-6"
  }, [_vm._m(2), _c('Dropdown', {
    attrs: {
      "options": _vm.coreClassVariantList,
      "default": _vm.selectedVariant,
      "placeholder": "Select Class",
      "optionLabel": "variantName",
      "disabled": !_vm.selectedClass
    },
    model: {
      value: _vm.selectedVariant,
      callback: function callback($$v) {
        _vm.selectedVariant = $$v;
      },
      expression: "selectedVariant"
    }
  })], 1) : _vm._e(), _vm._l(_vm.selectedInstructorList, function (instructor, idx) {
    return _c('div', {
      key: 'instructor-' + idx,
      staticClass: "mb-6"
    }, [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
    }, [_vm._v("Instructor "), !_vm.visibleInstructor ? _c('span', {
      staticClass: "inline-block text-neutral-300"
    }, [_vm._v("(Please select the class first)")]) : _vm._e(), _c('span', {
      staticClass: "text-red"
    }, [_vm._v(" *")])]), _c('div', {
      staticClass: "flex items-center w-full"
    }, [_c('div', {
      staticClass: "w-full"
    }, [_c('Dropdown', {
      attrs: {
        "options": _vm.filteredInstructorList,
        "default": instructor.selectedInstructor,
        "placeholder": "Select Instructor",
        "optionLabel": "name",
        "error": _vm.error['instructorId'],
        "disabled": !_vm.visibleInstructor,
        "maxHeight": "200px",
        "isLoadData": _vm.isLoadDataInstructor,
        "enableSearch": ""
      },
      on: {
        "search": _vm.searchInstructor
      },
      model: {
        value: instructor.selectedInstructor,
        callback: function callback($$v) {
          _vm.$set(instructor, "selectedInstructor", $$v);
        },
        expression: "instructor.selectedInstructor"
      }
    })], 1), _vm.selectedInstructorList.length > 1 ? _c('div', {
      staticClass: "w-10 flex justify-end ml-4"
    }, [_c('Button', {
      attrs: {
        "type": "delete",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        },
        "tooltip": {
          show: true,
          text: 'Delete'
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteInstructor(idx);
        }
      }
    })], 1) : _vm._e()])]);
  }), _c('div', {
    staticClass: "mb-6"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add More Instructor",
      "disabled": !_vm.visibleInstructor,
      "size": "compact",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addInstructor();
      }
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Status")]), _c('Dropdown', {
    attrs: {
      "options": _vm.statusList,
      "optionLabel": "name",
      "default": _vm.selectedStatus
    },
    model: {
      value: _vm.selectedStatus,
      callback: function callback($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  })], 1)], 2), _c('div', {
    staticClass: "form w-1/2 p-6"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isAvailableFromStart,
      expression: "isAvailableFromStart"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "eligible_from_start_checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.isAvailableFromStart) ? _vm._i(_vm.isAvailableFromStart, null) > -1 : _vm.isAvailableFromStart
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isAvailableFromStart,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isAvailableFromStart = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isAvailableFromStart = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isAvailableFromStart = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": "eligible_from_start_checkbox"
    }
  }, [_vm._v("All Items Eligible from Start")])]), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Zoom Link",
      "borderEnabled": true,
      "placeholder": "Zoom Link"
    },
    model: {
      value: _vm.zoomLink,
      callback: function callback($$v) {
        _vm.zoomLink = $$v;
      },
      expression: "zoomLink"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Host Key",
      "borderEnabled": true,
      "placeholder": "Host Key"
    },
    model: {
      value: _vm.zoomHostKey,
      callback: function callback($$v) {
        _vm.zoomHostKey = $$v;
      },
      expression: "zoomHostKey"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Telegram Link",
      "borderEnabled": true,
      "placeholder": "Telegram Link"
    },
    model: {
      value: _vm.telegramLink,
      callback: function callback($$v) {
        _vm.telegramLink = $$v;
      },
      expression: "telegramLink"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": _vm.phoneInputType,
      "placeholder": "08xxxx",
      "label": "Customer Service Number",
      "maxlength": "15",
      "autocomplete": "off",
      "isStayShow": "",
      "borderEnabled": "",
      "error": _vm.generalError['customerServiceNumber']
    },
    model: {
      value: _vm.customerServiceNumber,
      callback: function callback($$v) {
        _vm.customerServiceNumber = $$v;
      },
      expression: "customerServiceNumber"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Schedule Start End PMO (Optional)",
      "borderEnabled": true,
      "placeholder": "Schedule Start End PMO"
    },
    model: {
      value: _vm.scheduleStartEndPMO,
      callback: function callback($$v) {
        _vm.scheduleStartEndPMO = $$v;
      },
      expression: "scheduleStartEndPMO"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Schedule Code (Optional)",
      "borderEnabled": true,
      "placeholder": "Schedule Code"
    },
    model: {
      value: _vm.scheduleCodePMO,
      callback: function callback($$v) {
        _vm.scheduleCodePMO = $$v;
      },
      expression: "scheduleCodePMO"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Time Limit")]), _c('Dropdown', {
    attrs: {
      "options": _vm.timeLimitOptions,
      "placeholder": "Select Time Limit",
      "optionLabel": "label",
      "default": _vm.selectedTimeLimit
    },
    model: {
      value: _vm.selectedTimeLimit,
      callback: function callback($$v) {
        _vm.selectedTimeLimit = $$v;
      },
      expression: "selectedTimeLimit"
    }
  })], 1)])])] : _vm._e(), _vm.selectedTab === 'Activities' ? [_vm.isFetchActivityData ? _c('div', {
    staticClass: "shadow-small rounded-lg bg-white p-56"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "shadow-small rounded-lg bg-white p-6"
  }, [_vm.activities.length === 0 ? _c('div', {
    staticClass: "text-center py-16"
  }, [_c('p', {
    staticClass: "text-neutral-500 mb-3"
  }, [_vm._v("No activities added")]), _c('Button', {
    attrs: {
      "buttonText": "Add New Activity",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.addNewActivity();
      }
    }
  })], 1) : _c('div', {}, [_vm._l(_vm.activities, function (activity, index) {
    return _c('div', {
      key: 'activity-' + index
    }, [_c('ActivityForm', {
      key: _vm.refresh,
      ref: 'activityForm' + index,
      refInFor: true,
      attrs: {
        "isLastIndex": index === _vm.activities.length - 1 ? true : false,
        "data": activity,
        "index": index,
        "error": _vm.errorActivity[index],
        "childError": _vm.errorChildActivity[index],
        "classId": _vm.selectedClass && _vm.selectedClass.id,
        "primaryInstructor": _vm.selectedInstructorList,
        "initialInstructorList": _vm.instructorList,
        "selectedWorkflow": _vm.selectedWorkflow,
        "isError": _vm.activityErrorHighlight[index]
      },
      on: {
        "changePosition": function changePosition($event) {
          var _vm2;

          return (_vm2 = _vm).changePosition.apply(_vm2, ['activity'].concat(Array.prototype.slice.call(arguments)));
        },
        "changePositionChild": function changePositionChild($event) {
          var _vm3;

          return (_vm3 = _vm).changePosition.apply(_vm3, ['child'].concat(Array.prototype.slice.call(arguments)));
        },
        "delete": function _delete($event) {
          var _vm4;

          return (_vm4 = _vm).openDeleteModal.apply(_vm4, ['activity'].concat(Array.prototype.slice.call(arguments)));
        },
        "deleteChild": function deleteChild($event) {
          var _vm5;

          return (_vm5 = _vm).openDeleteModal.apply(_vm5, ['child'].concat(Array.prototype.slice.call(arguments)));
        },
        "deleteInstructorActivityItem": _vm.deleteInstructorActivityItem,
        "onChange": function onChange($event) {
          return _vm.updateActivities();
        }
      }
    })], 1);
  }), _c('Button', {
    attrs: {
      "buttonText": "Add New Activity",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.addNewActivity();
      }
    }
  })], 2)])] : _vm._e(), _vm.selectedTab === 'Students' ? [_c('div', {
    staticClass: "shadow-small rounded-lg bg-white flex"
  }, [_c('div', {
    staticClass: "form p-6 transition-all duration-200",
    class: [_vm.isAddStudentActive ? 'w-1/2' : 'w-full']
  }, [_c('div', {
    staticClass: "flex w-full justify-between mb-4 items-center border-b border-neutral-50 pb-4"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("List of Students ")]), !_vm.isAddStudentActive ? _c('Button', {
    attrs: {
      "buttonText": "Add Students",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        _vm.isAddStudentActive = !_vm.isAddStudentActive;
      }
    }
  }) : _vm._e()], 1), _c('div', [_vm.studentList.length > 0 ? _c('div', [_c('StudentTable', {
    attrs: {
      "columns": _vm.columnsOfStudentTable,
      "list": _vm.studentList
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Action' ? _c('div', [_c('Button', {
          staticClass: "ml-3",
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toogleDeleteStudentAlertModal(entry);
            }
          }
        })], 1) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 2683539936)
  }), _vm.totalRowsStudent > _vm.sizeStudent ? _c('div', {
    staticClass: "mt-3 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.pageForPaginationStudent,
      "totalCount": _vm.totalRowsStudent,
      "pageSize": _vm.sizeStudent
    },
    on: {
      "onPageChange": _vm.onPageChangeStudent
    }
  })], 1) : _vm._e()], 1) : _c('div', {
    staticClass: "w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center"
  }, [_c('p', [_vm._v("No student available")])])])]), _vm.isAddStudentActive ? _c('div', {
    staticClass: "form p-6 shadow-border-l w-1/2 transition-all duration-200"
  }, [_c('div', {
    staticClass: "flex justify-between items-center mb-4 border-b border-neutral-50 pb-4"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Add Students ")]), _c('span', {
    staticClass: "cursor-pointer",
    on: {
      "click": function click($event) {
        _vm.isAddStudentActive = !_vm.isAddStudentActive;
      }
    }
  }, [_c('Close')], 1)]), _c('div', [_c('div', {
    staticClass: "border rounded-lg p-5"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('p', {
    staticClass: "mb-3 text-sm"
  }, [_vm._v("1. Apply filter")]), _c('div', {
    staticClass: "bg-neutral-100 rounded-lg p-3"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Source")]), _c('Dropdown', {
    attrs: {
      "options": _vm.source,
      "default": _vm.selectedSource,
      "placeholder": "Select Source",
      "optionLabel": "name"
    },
    model: {
      value: _vm.selectedSource,
      callback: function callback($$v) {
        _vm.selectedSource = $$v;
      },
      expression: "selectedSource"
    }
  })], 1), _vm.selectedSource.value === 'SCHOLARSHIP' ? _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Program")]), _c('Dropdown', {
    attrs: {
      "options": _vm.scholarshipPrograms,
      "default": _vm.scholarshipProgramSelected,
      "placeholder": "Select Class Type",
      "optionLabel": "name"
    },
    model: {
      value: _vm.scholarshipProgramSelected,
      callback: function callback($$v) {
        _vm.scholarshipProgramSelected = $$v;
      },
      expression: "scholarshipProgramSelected"
    }
  })], 1) : _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Program")]), _c('Dropdown', {
    attrs: {
      "disabled": _vm.isDisabled,
      "options": _vm.programs,
      "default": _vm.selectedProgramStudent,
      "placeholder": "Select Class Type",
      "optionLabel": "name"
    },
    model: {
      value: _vm.selectedProgramStudent,
      callback: function callback($$v) {
        _vm.selectedProgramStudent = $$v;
      },
      expression: "selectedProgramStudent"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(" Status "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedProgramStudent.value === '',
      expression: "selectedProgramStudent.value === ''"
    }],
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("(Please select the Program first)")])]), _c('Dropdown', {
    attrs: {
      "disabled": _vm.isDisabled || _vm.selectedProgramStudent.value === '',
      "options": _vm.pipelineStage,
      "default": _vm.selectedPipelineStage,
      "placeholder": "Select Status",
      "optionLabel": "name"
    },
    model: {
      value: _vm.selectedPipelineStage,
      callback: function callback($$v) {
        _vm.selectedPipelineStage = $$v;
      },
      expression: "selectedPipelineStage"
    }
  })], 1), _c('div', {
    staticClass: "flex items-center"
  }, [_c('Button', {
    staticClass: "mr-3",
    attrs: {
      "buttonText": "Apply",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.getProspectList();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Reset",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.resetFilterStudent();
      }
    }
  })], 1)])]), _c('div', [_c('p', {
    staticClass: "mb-3 text-sm"
  }, [_vm._v("2. Add student")]), _vm.isFormRegisterVisible ? _c('div', {
    staticClass: "bg-neutral-100 rounded-lg p-3"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "Enter name",
      "label": "Name",
      "isStayShow": "",
      "autocomplete": "off",
      "borderEnabled": "",
      "error": _vm.generalError['userFullName']
    },
    model: {
      value: _vm.userFullName,
      callback: function callback($$v) {
        _vm.userFullName = $$v;
      },
      expression: "userFullName"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "Enter email",
      "label": "Email",
      "isStayShow": "",
      "autocomplete": "off",
      "borderEnabled": "",
      "error": _vm.generalError['userEmail']
    },
    model: {
      value: _vm.userEmail,
      callback: function callback($$v) {
        _vm.userEmail = $$v;
      },
      expression: "userEmail"
    }
  })], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('TextField', {
    attrs: {
      "type": _vm.phoneInputType,
      "placeholder": "Enter phone number",
      "label": "Phone Number",
      "maxlength": "15",
      "autocomplete": "off",
      "borderEnabled": "",
      "isStayShow": "",
      "error": _vm.generalError['userPhoneNumber']
    },
    model: {
      value: _vm.userPhoneNumber,
      callback: function callback($$v) {
        _vm.userPhoneNumber = $$v;
      },
      expression: "userPhoneNumber"
    }
  })], 1), _c('div', {
    staticClass: "mb-5"
  }, [_vm._m(3), _c('Dropdown', {
    attrs: {
      "options": _vm.dpList,
      "optionLabel": "name",
      "default": _vm.ecommerce,
      "placeholder": "Select Ecommerce"
    },
    on: {
      "change": function change($event) {
        return _vm.searchByEcommerce();
      }
    },
    model: {
      value: _vm.ecommerce,
      callback: function callback($$v) {
        _vm.ecommerce = $$v;
      },
      expression: "ecommerce"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_vm._m(4), _c('TextField', {
    attrs: {
      "placeholder": "Enter voucher",
      "autocomplete": "off",
      "borderEnabled": ""
    },
    model: {
      value: _vm.voucerCode,
      callback: function callback($$v) {
        _vm.voucerCode = $$v;
      },
      expression: "voucerCode"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_vm._m(5), _c('TextField', {
    attrs: {
      "placeholder": "Enter voucher",
      "autocomplete": "off",
      "borderEnabled": ""
    },
    model: {
      value: _vm.redeemCode,
      callback: function callback($$v) {
        _vm.redeemCode = $$v;
      },
      expression: "redeemCode"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "leading-0 flex items-center gap-3 relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isCurator,
      expression: "isCurator"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "female",
      "disabled": _vm.modeForm === 'Detail'
    },
    domProps: {
      "checked": Array.isArray(_vm.isCurator) ? _vm._i(_vm.isCurator, null) > -1 : _vm.isCurator
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isCurator,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isCurator = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isCurator = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isCurator = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "female"
    }
  }, [_vm._v("Mark as Kurator")])])]), _c('div', {
    staticClass: "mb-3 flex justify-center"
  }, [_c('Button', {
    attrs: {
      "size": "compact",
      "buttonText": "Add New Student"
    },
    on: {
      "action": _vm.addNewStudent
    }
  })], 1)]) : _c('div', {
    staticClass: "bg-neutral-100 rounded-lg p-3"
  }, [_c('div', {
    staticClass: "relative mb-6"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "enterKeyAction": _vm.searchProspect,
      "borderEnabled": "",
      "withIcon": "",
      "placeholder": "Search name, email, phone number"
    },
    on: {
      "keyUpAction": _vm.searchProspect
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedProspect.length > 0,
      expression: "selectedProspect.length > 0"
    }],
    staticClass: "flex justify-between items-center"
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.selectedProspect.length) + " selected students")]), _c('Button', {
    attrs: {
      "buttonText": "Add Selected Student",
      "size": "compact"
    },
    on: {
      "action": function action($event) {
        return _vm.showAddStudentModal();
      }
    }
  })], 1), _vm.prospectList.length > 0 ? _c('div', [_c('DataTable', {
    attrs: {
      "tableHeader": ['Name', 'Phone Number'],
      "tableHeaderAlign": ['left', 'left'],
      "checkbox": true,
      "actionCheckbox": _vm.actionCheckbox,
      "resetSelectedData": _vm.isReset,
      "list": _vm.prospectList,
      "listAlign": ['left', 'left'],
      "actionButtons": [{
        additionalStyle: 'p-1.5',
        height: '20',
        width: '20',
        viewBox: '0 0 20 20',
        paths: [{
          d: 'M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM13.75 10.75H10.75V13.75C10.75 13.9489 10.671 14.1397 10.5303 14.2803C10.3897 14.421 10.1989 14.5 10 14.5C9.80109 14.5 9.61032 14.421 9.46967 14.2803C9.32902 14.1397 9.25 13.9489 9.25 13.75V10.75H6.25C6.05109 10.75 5.86032 10.671 5.71967 10.5303C5.57902 10.3897 5.5 10.1989 5.5 10C5.5 9.80109 5.57902 9.61032 5.71967 9.46967C5.86032 9.32902 6.05109 9.25 6.25 9.25H9.25V6.25C9.25 6.05109 9.32902 5.86032 9.46967 5.71967C9.61032 5.57902 9.80109 5.5 10 5.5C10.1989 5.5 10.3897 5.57902 10.5303 5.71967C10.671 5.86032 10.75 6.05109 10.75 6.25V9.25H13.75C13.9489 9.25 14.1397 9.32902 14.2803 9.46967C14.421 9.61032 14.5 9.80109 14.5 10C14.5 10.1989 14.421 10.3897 14.2803 10.5303C14.1397 10.671 13.9489 10.75 13.75 10.75Z',
          fill: '#3b82f6'
        }],
        next: _vm.addStudent
      }],
      "counter": _vm.counter,
      "totalPages": _vm.totalPages,
      "totalItems": _vm.totalRows,
      "size": _vm.size
    }
  }), _vm.totalRows > 10 ? _c('div', {
    staticClass: "mt-3 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.pageForPagination,
      "totalCount": _vm.totalRows,
      "pageSize": _vm.size
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1) : _c('div', {
    staticClass: "w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center"
  }, [_c('p', [_vm._v("No student available")])])])])])])]) : _vm._e()])] : _vm._e(), _vm.selectedTab === 'Instructor Feedback' ? [_c('div', {
    staticClass: "shadow-small rounded-lg bg-white p-6"
  }, [_c('div', {
    staticClass: "flex items-center justify-between w-full mb-5"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Select Feedback")]), _c('Dropdown', {
    attrs: {
      "options": _vm.feedbackList,
      "disabled": _vm.feedbackList.length === 0 || !_vm.feedbackList,
      "default": _vm.feedbakSelected,
      "placeholder": "No feedback selected",
      "optionLabel": "title"
    },
    model: {
      value: _vm.feedbakSelected,
      callback: function callback($$v) {
        _vm.feedbakSelected = $$v;
      },
      expression: "feedbakSelected"
    }
  })], 1), _c('div', {
    staticClass: "pt-3"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Download",
      "type": "secondary",
      "disabled": _vm.studentFeedback.length === 0
    },
    on: {
      "action": function action($event) {
        return _vm.downloadFeedback();
      }
    }
  })], 1)]), !_vm.isFetchFeedbackInstructor && _vm.studentFeedback.length > 0 ? _c('div', [_c('TableComponent', {
    attrs: {
      "columns": _vm.feedbackColumn,
      "list": _vm.studentFeedback
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var entry = _ref2.entry,
            column = _ref2.column;
        return [column.field === 'student_name' ? _c('div', {
          staticClass: "truncate"
        }, [_vm._v(" " + _vm._s(entry[column.field] || 'undefined') + " ")]) : _c('div', {}, [_vm._v(_vm._s(_vm.sliceString(entry[column.field]) || '-'))])];
      }
    }], null, false, 1877104473)
  })], 1) : _vm._e(), !_vm.isFetchFeedbackInstructor && _vm.studentFeedback.length === 0 ? _c('div', {
    staticClass: "flex justify-center items-center py-32"
  }, [_c('p', [_vm._v("No data available")])]) : _vm._e()])] : _vm._e(), _vm.selectedTab === 'Grade' ? [_c('div', {
    staticClass: "shadow-small rounded-lg bg-white p-6"
  }, [!_vm.isLevel1Available ? _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_vm.isFetching ? [_c('vue-simple-spinner', {
    staticClass: "py-32",
    attrs: {
      "size": "large"
    }
  })] : _c('div', {
    staticClass: "py-20"
  }, [_c('img', {
    staticClass: "mx-auto",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  }), _c('div', {
    staticClass: "font-bold text-2xl py-5"
  }, [_vm._v("This schedule doesn’t have grading system")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Create New",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.showCreateNewGradingModal();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Copy from Other Schedule",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.showCopyFromScheduleModal();
      }
    }
  })], 1)])], 2) : _c('div', [_c('div', {
    staticClass: "tab flex mb-3"
  }, [_vm._l(_vm.tabLevel, function (tabGrading, tabIndex) {
    return _c('div', {
      key: tabIndex,
      staticClass: "tab__item text-sm px-5 pb-2 rounded-tl-lg cursor-pointer font-semibold",
      class: [tabGrading === _vm.levelTabSelected ? 'text-yellow border-yellow border-b-2' : 'text-neutral-400 border-neutral-400 border-b'],
      on: {
        "click": function click($event) {
          return _vm.selectGradingTab(tabGrading);
        }
      }
    }, [_vm._v(" " + _vm._s("Level ".concat(tabGrading)) + " ")]);
  }), _c('div', {
    staticClass: "text-sm px-5 pb-2 rounded-tl-lg font-semibold",
    class: ['Summary' === _vm.levelTabSelected ? 'text-yellow border-yellow border-b-2 ' : 'text-neutral-400 border-neutral-400 border-b ', _vm.isSummaryTabEnable ? 'cursor-pointer' : 'cursor-not-allowed'],
    on: {
      "click": function click($event) {
        return _vm.selectGradingTab('Summary');
      }
    }
  }, [_vm._v(" Summary ")])], 2), [_c('div', {}, [_vm.isFetching ? [_c('vue-simple-spinner', {
    staticClass: "py-32",
    attrs: {
      "size": "large"
    }
  })] : [_c('div', {
    staticClass: "flex justify-between items-center mb-3"
  }, [_c('div', {
    staticClass: "font-semibold text-neutral-500"
  }, [_vm._v(" " + _vm._s(Number.isInteger(this.levelTabSelected) ? "Level ".concat(this.levelTabSelected) : this.levelTabSelected) + " ")]), _c('div', {
    staticClass: "flex items-center gap-2"
  }, [_vm.levelTabSelected !== 'Summary' ? _c('Button', {
    attrs: {
      "buttonText": "Add Item",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.showEditComponentModal(null, 'New');
      }
    }
  }) : _vm._e(), _vm.isAddLevelButtonEnable ? _c('Button', {
    attrs: {
      "buttonText": "Add Level",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.addLevel();
      }
    }
  }) : _vm._e(), _vm.levelTabSelected === 'Summary' ? _c('Button', {
    attrs: {
      "buttonText": "Download",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.donwloadGradingSummary();
      }
    }
  }) : _vm._e()], 1)]), _c('TableComponent', {
    attrs: {
      "columns": _vm.levelTabSelected === 'Summary' ? _vm.columnsSummary : _vm.columnsGrading,
      "list": _vm.gradingList,
      "drag": _vm.levelTabSelected === 'Summary' ? false : true
    },
    on: {
      "handleChange": _vm.handleChangeTable,
      "handleMutateTable": _vm.handleMutateList
    },
    scopedSlots: _vm._u([_vm.levelTabSelected === 'Summary' ? {
      key: "default",
      fn: function fn(_ref3) {
        var entry = _ref3.entry,
            column = _ref3.column;
        return [column.field === 'student_name' ? _c('div', [_vm._v(" " + _vm._s(entry[column.field] || 'undefined') + " ")]) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s(entry[column.field] || 0))])];
      }
    } : {
      key: "default",
      fn: function fn(_ref4) {
        var entry = _ref4.entry,
            column = _ref4.column;
        return [column.field === 'btn' ? _c('div', {
          staticClass: "flex items-center gap-2 w-1/10"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "primary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showEditComponentModal(entry, 'Edit');
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showDeleteConfirmationModal(entry);
            }
          }
        })], 1) : column.field === 'scoringSchemaGroupItems' ? _c('div', [entry.scoringSchemaGroupItems.length === 0 ? _c('div', {
          staticClass: "pl-12"
        }, [_vm._v("-")]) : _c('div', _vm._l(entry.scoringSchemaGroupItems, function (item) {
          return _c('div', {
            key: item.id
          }, [_vm._v(_vm._s(_vm.percentageAverage(item, item.percentage)) + "% " + _vm._s(item.title))]);
        }), 0)]) : _c('div', {}, [_vm._v(_vm._s(entry[column.field] || '-'))])];
      }
    }], null, true)
  })]], 2)]], 2)])] : _vm._e(), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isEditComponentModal,
      "width": "950px",
      "id": "editComponent"
    },
    on: {
      "close": function close($event) {
        return _vm.hideEditComponentModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-left my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v(_vm._s(_vm.itemMode) + " Component")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "borderEnabled": "",
      "label": "Title",
      "type": "text"
    },
    model: {
      value: _vm.editComponentSelected.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editComponentSelected, "title", $$v);
      },
      expression: "editComponentSelected.title"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "borderEnabled": "",
      "label": "Topic",
      "type": "text"
    },
    model: {
      value: _vm.editComponentSelected.topic,
      callback: function callback($$v) {
        _vm.$set(_vm.editComponentSelected, "topic", $$v);
      },
      expression: "editComponentSelected.topic"
    }
  })], 1), _vm._l(_vm.editComponentSelected.scoringSchemaGroupItems, function (item, itemIdx) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.itemMode === 'Edit',
        expression: "itemMode === 'Edit'"
      }],
      key: itemIdx + 'editComponent',
      staticClass: "mb-3"
    }, [_c('div', {
      staticClass: "flex gap-2"
    }, [_c('div', {
      staticClass: "w-1/2"
    }, [item.referenceType === 'Free' ? _c('div', [_c('TextField', {
      attrs: {
        "borderEnabled": "",
        "label": "Title",
        "type": "text",
        "default": item.title ? item.title : null,
        "placeholder": "Enter title..."
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1) : _vm._e(), item.referenceType === "Level ".concat(_vm.levelTabSelected - 1) || item.referenceType === 'Activity' ? _c('div', [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
    }, [_vm._v("Title")]), _c('Dropdown', {
      attrs: {
        "options": item.referenceType === 'Activity' ? _vm.activityItemOptions : _vm.referenceGradingList,
        "maxHeight": "500px",
        "default": item.title ? item.title : null,
        "placeholder": "Select activity",
        "optionLabel": item.referenceType === 'Activity' ? 'title' : ''
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    })], 1) : _vm._e()]), !_vm.editComponentSelected.isAverage ? _c('div', {
      staticClass: "w-1/5"
    }, [_c('TextField', {
      attrs: {
        "borderEnabled": "",
        "label": "Percentage",
        "type": "number",
        "indexData": itemIdx
      },
      on: {
        "input": _vm.handleLimitPercentage
      },
      model: {
        value: item.percentage,
        callback: function callback($$v) {
          _vm.$set(item, "percentage", $$v);
        },
        expression: "item.percentage"
      }
    })], 1) : _vm._e(), _c('div', {
      staticClass: "w-1/5"
    }, [_c('label', {
      staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
    }, [_vm._v("Reference")]), _c('Dropdown', {
      attrs: {
        "options": _vm.referenceTypeOptions,
        "indexData": itemIdx,
        "default": item.referenceType ? item.referenceType : _vm.referenceTypeSelected
      },
      on: {
        "onChangeSelected": _vm.handleSwitchingReferenceType
      },
      model: {
        value: item.referenceType,
        callback: function callback($$v) {
          _vm.$set(item, "referenceType", $$v);
        },
        expression: "item.referenceType"
      }
    })], 1), _c('div', {
      staticClass: "my-auto pt-5"
    }, [_c('Button', {
      attrs: {
        "type": "secondary",
        "size": "icon",
        "icon": function icon() {
          return import(
          /* webpackChunkName: 'icon' */
          '@/components/Icons/Trash');
        }
      },
      on: {
        "action": function action($event) {
          return _vm.deleteComponentItems(item, itemIdx);
        }
      }
    })], 1)])]);
  }), _vm.editComponentSelected.scoringSchemaGroupItems && _vm.itemMode === 'Edit' ? _c('div', [_c('div', {
    staticClass: "text-red mb-3 text-xs"
  }, [_vm._v("*total percentage must be 100")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editComponentSelected.isAverage,
      expression: "editComponentSelected.isAverage"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "average"
    },
    domProps: {
      "checked": Array.isArray(_vm.editComponentSelected.isAverage) ? _vm._i(_vm.editComponentSelected.isAverage, null) > -1 : _vm.editComponentSelected.isAverage
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.editComponentSelected.isAverage,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.editComponentSelected, "isAverage", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.editComponentSelected, "isAverage", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.editComponentSelected, "isAverage", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "text-xs pl-2",
    attrs: {
      "for": "average"
    }
  }, [_vm._v("Average Percentage")])]) : _vm._e(), _vm.itemMode === 'Edit' ? _c('div', {
    staticClass: "flex justify-start my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "+ Add",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.addComponentItems();
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "flex justify-end my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Save Component",
      "disabled": !_vm.isSaveItemComponentEnable
    },
    on: {
      "action": function action($event) {
        return _vm.saveGradingComponent();
      }
    }
  })], 1)], 2)])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isDeleteConfirmationModal,
      "id": "createNewGrading"
    },
    on: {
      "close": function close($event) {
        return _vm.hideDeleteConfirmationModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Are you sure to delete?")]), _c('div', {
    staticClass: "flex justify-center gap-4 my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "delete"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteGradingData();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideDeleteConfirmationModal();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isCreateNewGradingModal,
      "width": "",
      "id": "createNewGrading"
    },
    on: {
      "close": function close($event) {
        return _vm.hideCreateNewGradingModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-left my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Choose a reference")]), _c('div', {}, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Reference")]), _c('Dropdown', {
    attrs: {
      "options": _vm.createNewGradingOptions,
      "default": _vm.newGradingSelected
    },
    model: {
      value: _vm.newGradingSelected,
      callback: function callback($$v) {
        _vm.newGradingSelected = $$v;
      },
      expression: "newGradingSelected"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-end my-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Save"
    },
    on: {
      "action": function action($event) {
        return _vm.createNewGrading();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isCopyFromScheduleModal,
      "width": "640px",
      "overflowModal": false,
      "id": "copyFromSchedule"
    },
    on: {
      "close": function close($event) {
        return _vm.hideCopyFromScheduleModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "my-6 text-left relative"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Select From Other Schedule")]), _c('div', {
    staticClass: "relative w-full mb-5"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Schedule Name")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.searchingSchedule,
      "additionalClass": "pr-10",
      "borderEnabled": "",
      "placeholder": "Search schedule by name..."
    },
    model: {
      value: _vm.copyFromScheduleQuery,
      callback: function callback($$v) {
        _vm.copyFromScheduleQuery = $$v;
      },
      expression: "copyFromScheduleQuery"
    }
  }), _vm.copyFromScheduleQuery !== '' && _vm.isSearchingSchedule ? _c('div', {
    staticClass: "absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer",
    on: {
      "click": _vm.clearKeyWord
    }
  }, [_c('Close', {
    attrs: {
      "color": "#58595B"
    }
  })], 1) : _c('div', {
    staticClass: "absolute right-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "#58595B"
    }
  })], 1)], 1), _vm.isSearchingSchedule ? _c('div', {
    staticClass: "absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10"
  }, [_vm.scheduleClassList.length > 0 && !_vm.isFetching ? [_c('div', {
    ref: "scheduleClassList"
  }, _vm._l(_vm.scheduleClassList, function (sch) {
    return _c('div', {
      key: sch.id,
      staticClass: "py-1 px-3 text-white cursor-pointer truncate hover:bg-yellow-primary hover:text-white font-normal last:pb-0",
      on: {
        "click": function click($event) {
          return _vm.selectSchedule(sch);
        }
      }
    }, [_vm._v(" " + _vm._s(sch.nama) + " ")]);
  }), 0)] : _vm._e(), _vm.scheduleClassList.length === 0 && !_vm.isFetching ? [_c('p', {
    staticClass: "py-1 px-3 text-white font-normal text-center"
  }, [_vm._v("Schedule is not found.")])] : _vm._e(), _vm.isFetching ? [_c('vue-simple-spinner', {
    staticClass: "py-2",
    attrs: {
      "size": "large"
    }
  })] : _vm._e()], 2) : _vm._e()]), _c('div', {
    staticClass: "flex justify-end",
    class: _vm.isSearchingSchedule ? _vm.marginBtm : 'mb-5'
  }, [_c('Button', {
    attrs: {
      "buttonText": "Copy Schedule",
      "disabled": !_vm.selectedScheduleId
    },
    on: {
      "action": function action($event) {
        return _vm.generateGradingData('COPY');
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isAddStudentModal,
      "width": "640px",
      "id": "addStudent"
    },
    on: {
      "close": function close($event) {
        return _vm.hideAddStudentModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Are you sure to add student to this class?")]), _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v(" You are about to permanently add new student into the Student List. You will not be able to remove student from the student list one you’ve proceed. This action cannot be undone. ")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideAddStudentModal();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Proceed",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.enrollStudent();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.alreadyExistModal,
      "width": "640px",
      "id": "alreadyExistModal"
    },
    on: {
      "close": function close($event) {
        return _vm.hideAlreadyExistModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Failed to Enroll Student")]), _c('div', {
    staticClass: "overflow-y-auto h-36 border rounded-lg p-2 text-left mb-3"
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.failedStudent.join(', ')))])]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Ok",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideAlreadyExistModal();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isModalActivityTemplate,
      "width": "640px",
      "id": "activityTemplate"
    },
    on: {
      "close": _vm.toggleModalActivityTemplate
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Do you want to use activity template?")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Yes",
      "type": "secondary"
    },
    on: {
      "action": _vm.duplicateActivityTemplate
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "No",
      "type": "primary"
    },
    on: {
      "action": _vm.dontDuplicateActivityTemplate
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleDeleteModal,
      "width": "640px",
      "id": "delete"
    },
    on: {
      "close": function close($event) {
        return _vm.closeDeleteModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-3"
  }, [_vm._v("Are you sure to delete this " + _vm._s(_vm.selectedTypeToBeDeleted) + "?")]), _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v("This action can not be undone")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteItem();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.closeDeleteModal();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isInstructorWarningModal
    },
    on: {
      "close": function close($event) {
        return _vm.closeInstructorWarningModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-xl font-bold mb-3"
  }, [_vm._v("Instructor " + _vm._s(((_vm$errorMessage = _vm.errorMessage) === null || _vm$errorMessage === void 0 ? void 0 : _vm$errorMessage.status) === 'NA' ? 'Not Available' : 'Is Conflict'))]), _c('p', {
    staticClass: "text-base mb-4"
  }, [_vm._v(_vm._s((_vm$errorMessage2 = _vm.errorMessage) === null || _vm$errorMessage2 === void 0 ? void 0 : _vm$errorMessage2.message))]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": ((_vm$errorMessage3 = _vm.errorMessage) === null || _vm$errorMessage3 === void 0 ? void 0 : _vm$errorMessage3.status) === 'NA' ? 'Edit Availability' : 'See Conflicted Schedule',
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.openNewTab();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleDeleteStudentAlert
    },
    on: {
      "close": function close($event) {
        return _vm.toogleDeleteStudentAlertModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('h1', {
    staticClass: "text-xl font-bold mb-3"
  }, [_vm._v("Remove Student Confirmation")]), _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v("You are about to permanently remove student and their class progress from this class schedule’s Student List. This action cannot be undone.")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiaryNonBorder"
    },
    on: {
      "action": function action($event) {
        return _vm.toogleDeleteStudentAlertModal();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Proceed",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteStudentLmsClass();
      }
    }
  })], 1)])])], 2), !this.showModal ? _c('div', {
    staticClass: "border-2 table-cell align-middle"
  }, [_vm._m(6)]) : _vm._e()], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('span', {
    staticClass: "font-semibold block text-brown text-lg"
  }, [_vm._v("Issues found in this Class Schedule")]), _c('span', {
    staticClass: "text-xs block text-brown-500 mt-1"
  }, [_vm._v("System has failed to auto-generate meeting link for your Webinar activity.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Type"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v("Class Variant"), _c('span', {
    staticClass: "text-red"
  }, [_vm._v(" *")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(" Digital Platform "), _c('span', {
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("(Prakerja Only)")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(" Voucher Code "), _c('span', {
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("(Prakerja Only)")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3 block"
  }, [_vm._v(" Redeem Code "), _c('span', {
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("(Prakerja Only)")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fixed z-60 pt-16 top-0 left-0 w-full h-full table transition-opacity",
    staticStyle: {
      "background-color": "rgba(0, 0, 0, 0.5)",
      "transition": "opacity 0.3s ease"
    }
  }, [_c('div', {
    staticClass: "relative rounded-xl py-3 px-6 bg-red-10 border-2 border-red-400",
    staticStyle: {
      "width": "482px",
      "margin": "0px auto",
      "box-shadow": "0 2px 8px rgba(0, 0, 0, 0.33)",
      "transition": "all 0.3s ease",
      "-webkit-transform": "scale(1.1)",
      "transform": "scale(1.1)"
    }
  }, [_c('div', {
    staticClass: "text-lg font-semibold text-red-400"
  }, [_vm._v("You have exercise with time constraint!")]), _c('span', {
    staticClass: "text-12 text-red-400"
  }, [_vm._v("Please turn on 'Mark as Independent' to Submit")]), _c('img', {
    staticClass: "w-7 h-7 absolute top-6 right-7",
    attrs: {
      "src": require("@/assets/images/activition.png"),
      "alt": "Message"
    }
  })])]);
}]

export { render, staticRenderFns }